import React, { useState } from "react";
import Button from "../Button";

function Home() {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    const emailValidationError = validateEmail(event.target.value);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }
    setEmailError("");
  };

  const validateEmail = (email) => {
    if (!email) {
      console.log("Please provide an email address");
      return "Please provide an email address";
    }
    return "";
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }
    setSubmitLoading(true);
    const url =
      "https://script.google.com/macros/s/AKfycbwJ3vncZAKzrTgXnZNCMDrVS4qqdQxwwed9HZqAUF3MVLkAre7U17KRlFi91m2iwA/exec";
    const response = await fetch(
      `${url}?proxy=true&email=${encodeURIComponent(email)}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      setSubmitLoading(false);
      throw new Error("Failed to submit email address");
    }
    setEmail("");
    setIsSubmitted(true);
    setSubmitLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 font-montserrat">
      {/* <div className=" sm:bg-red-500 md:bg-yellow-500 lg:bg-green-600 ">.</div> */}
      <div className="max-w-lg mx-auto px-4">
        <h1 className="text-3xl sm:text-3xl md:text-4xl lg:text-4xl font-semibold text-center text-gray-800 mb-4">
          Be a part of the men's community that cares!
        </h1>
        <p className="text-base sm:text-lg md:text-xl lg:text-xl text-center text-gray-700 mb-8">
          Bande Bulao is a volunteer network that connects men who want to help
          each other in times of need. With the upcoming Bande Bulao app, you'll
          be able to call for help with just one button and get support from
          other volunteers in your area.
        </p>
        {!isSubmitted ? (
          <form
            className="flex flex-col items-center gap-4"
            onSubmit={handleFormSubmit}
          >
            <div className="w-full max-w-xs">
              <input
                type="text"
                placeholder="Enter your email address"
                className={`w-full max-w-xs px-4 py-2 rounded-l-full border ${
                  emailError ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent`}
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && (
                <p className="text-red-500 text-sm text-center">{emailError}</p>
              )}
            </div>

            <Button
              label={"Join the Community"}
              loading={submitLoading}
            ></Button>
          </form>
        ) : (
          <p className="text-lg font-semibold sm:text-lg md:text-xl lg:text-xl text-center text-transparent bg-gradient-text bg-clip-text mb-8">
            Thanks for joining! Stay tuned for updates on the Bande Bulao app.
          </p>
        )}
      </div>
    </div>
  );
}

export default Home;

import React from "react";
import { ProgressBar } from "react-loader-spinner";

function Button({ onClick, disabled, loading, label }) {
  return loading ? (
    loadingComponent()
  ) : (
    <button
      type="submit"
      className="bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 hover:from-purple-600 hover:via-pink-600 hover:to-red-600 text-base sm:text-lg md:text-xl lg:text-lg text-white font-semibold py-2 px-8 rounded-r-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {label}
    </button>
  );
}

export default Button;

const loadingComponent = () => {
  return (
    <ProgressBar
      height="80"
      width="80"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass="progress-bar-wrapper"
      borderColor="#ec4899"
      barColor="#9333ea"
    />
  );
};

import React from "react";
import Home from "./component/screen/HomeScreen.jsx";

const App = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default App;
